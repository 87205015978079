import { useMemo } from "react";
import { Pagination } from "react-bootstrap";
import "./styles.css";

interface TableFooterProps {
    pages: number;
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    totalRows?: number;
}

const TableFooter: React.FC<TableFooterProps> = ({
    pages,
    currentPage,
    setCurrentPage,
    totalRows
}) => {

    const paginationItems: React.ReactNode[] = useMemo(() => {
        const items: React.ReactNode[] = [];
        const numPage = 5;

        let leftSide = currentPage - numPage / 2 > 1;
        let rightSide = currentPage + numPage / 2 < pages;
        if (leftSide && rightSide) {
            leftSide = currentPage - numPage / 2 > 2;
            rightSide = currentPage + numPage / 2 < pages - 1;
        }

        if (leftSide) {
            items.push(
                <Pagination.Ellipsis
                    key="leftEllipsis"
                    onClick={() => setCurrentPage(currentPage - numPage)}
                />
            );
        }

        const start = Math.max(1, Math.round(currentPage - numPage / 2));
        const end = Math.min(pages, Math.round(currentPage + numPage / 2));
        for (let i = start; i <= end; i++) {
            items.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
                    {i}
                </Pagination.Item>
            );
        }

        if (rightSide) {
            items.push(
                <Pagination.Ellipsis
                    key="rightEllipsis"
                    onClick={() => setCurrentPage(currentPage + numPage)}
                />
            );
        }

        return items;
    }, [currentPage, pages]);

    return (
        <Pagination style={{ color: 'black' }}>
            <Pagination.First onClick={() => setCurrentPage(1)} />
            <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))} />
            {paginationItems}
            <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(pages, prev + 1))} />
            <Pagination.Last onClick={() => setCurrentPage(pages)} />
            {totalRows &&
                <Pagination.Item disabled>
                    {`Registros encontrados ${totalRows}`}
                </Pagination.Item>
            }
        </Pagination>
    )
};

export default TableFooter;