import * as lodash from "lodash";
import moment from "moment";
import "moment/locale/es";
import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { ToastOptions, toast } from "react-toastify";
import * as api from '../api';
import { ISchedulesByGroup } from "../interfaces/date.interface";
import { OfficeScheduleProps } from "../interfaces/officeScheduleProps";
import "../styles/officeShedule.css";

const OfficeSchedule: React.FC<OfficeScheduleProps> = ({ selectedCityGroup, selectedSubTurnType, handleScheduleChange, sedeSearch, transactionId }) => {
  const [schedulesByGroup, setSchedulesByGroup] = useState<ISchedulesByGroup[] | null>(null);
  const [loading, setLoading] = useState(true);
  const toastOptions: ToastOptions = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored"
  };
  useEffect(() => {
    const loadSchedulesByGroup = async () => {
      if (selectedCityGroup && selectedSubTurnType) {
        // Simula una llamada a una API para obtener los datos
        try {
          const schedulesByGroup: ISchedulesByGroup[] = await api.getSchedulesByGroupCity(selectedCityGroup, selectedSubTurnType.id, transactionId);
          setSchedulesByGroup(schedulesByGroup);
        } catch (error) {
          toast.error('Ocurrió un error inesperado al obtener los horarios, por favor refresque el navegador.', toastOptions);
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    }
    loadSchedulesByGroup();
  }, []);
  // Verificar si todos los scheduleHours están vacíos
  const allSchedulesEmpty = schedulesByGroup?.every((office) => office.schedules.every((schedule) => schedule.scheduleHours.length === 0));
  if (allSchedulesEmpty) {
    handleScheduleChange(null, null);
  }

  // se filtran las sedes en base al cuadro de busqueda
  const schedulesByGroupFilter = schedulesByGroup?.filter(office => !sedeSearch?.trim() || office.descriptionOffice.toUpperCase().includes(sedeSearch.toUpperCase()));
  const officesHtml = <div>
    {schedulesByGroupFilter && schedulesByGroupFilter.length > 0 ? (
      schedulesByGroupFilter?.map((office) => (
        <div key={office.officeId} className="office-container">
          <h2 className="office-title">{office.descriptionOffice}</h2>
          {office.schedules.length > 0 ? (
            <table className="schedule-table">
              <thead>
                <tr>
                  <th className="date-column">Fecha</th>
                  <th className="hours-column">Horarios</th>
                </tr>
              </thead>
              <tbody>
                {office.schedules.map((schedule, scheduleIndex) => (
                  <tr key={schedule.date}>
                    <td className={scheduleIndex < office.schedules.length - 1 ? 'td-schedule-border' : undefined}>
                      {lodash.capitalize(
                        moment(schedule.date, "YYYY-MM-DD").format(
                          "dddd - DD/MM/YYYY"
                        )
                      )}
                    </td>
                    <td className={scheduleIndex < office.schedules.length - 1 ? 'td-schedule-border' : undefined}>
                      {schedule.scheduleHours.map(
                        (scheduleHour, hourIndex) => (
                          <Button
                            key={`${office.officeId}|${scheduleHour.dateRequest}`}
                            className="schedule-button"
                            onClick={() =>
                              handleScheduleChange(
                                office.officeId,
                                scheduleHour
                              )
                            }
                          >
                            {scheduleHour.description}
                          </Button>
                        )
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="no-schedule-message">
              No hay turnos disponibles para esta sede.
            </p>
          )}
        </div>
      ))
    ) : (
      <p className="no-schedule-message"> No se encontraron sedes en la ciudad que coincidan con la busqueda.</p>
    )}
  </div>;
  const bodyHtml = <div>
    {allSchedulesEmpty ? (
      <p className="no-schedule-message">
        No se encontraron horarios en ninguna de las sedes de la ciudad.
        <span role="img" aria-label="X" className="animated-x">
          ❌
        </span>
      </p>
    ) : officesHtml}
  </div>;
  return (
    <div>
      {loading ? (
        <div className="spinner-div">
          <Spinner animation="border" style={{ width: '100px', height: '100px', alignSelf: 'center' }} />
        </div>
      ) : bodyHtml}
    </div>
  );
};

export default OfficeSchedule;