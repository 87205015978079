import * as yup from 'yup';
const allowedTypes = ['text/csv'];
export const uploadFileIcetexSchema = yup.object().shape({
    file: yup.mixed()
        .required('El archivo es requerido')
        .test('fileFormat', 'Solo se permite archivos con formato .csv', (value) => {
            return value && value[0] && allowedTypes.includes(value[0].type);
        }),
});

export type uploadFileIcetexType = yup.InferType<typeof uploadFileIcetexSchema>;

export const generateCertificateSchema = yup.object().shape({
    tickler: yup.string().required(),
    startDate: yup.string().required().test('is-empty', 'Agrega fecha inicio', function (value) {
        const { endDate } = this.parent;
        if (endDate && !value) return false
        else return true;
    }),
    endDate: yup.string().required()
        .test('is-empty', 'Agrega fecha fin', function (value) {
            const { startDate } = this.parent;
            if (startDate && !value) return false
            else return true;
        })
        .test('is-greater', 'Debe ser posterior a la fecha de inicio ', function (value) {
            const { startDate } = this.parent;
            if (!value || !startDate) return true;
            return new Date(startDate) <= new Date(value);
        }),
});

export type generateCertificateType = yup.InferType<typeof generateCertificateSchema>;