import * as formik from "formik";
import { useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { ToastOptions, toast } from "react-toastify";
import * as api from "../../api";
import Menu from "../../components/menu";
import "../../styles/uploadFileIcetex.css";
import {
    generateCertificateSchema,
    generateCertificateType,
    uploadFileIcetexSchema,
    uploadFileIcetexType,
} from "./uploadFileIcetex.schema";

const UploadFileIcetex: React.FC = () => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isLoadingSave, setLoadingSave] = useState(false);
    const [isLoadingCerticate, setLoadingCerticate] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const { Formik } = formik;
    document.title = "Subir archivo";

    const toastOptions: ToastOptions = {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
    };

    const onResetUpload = () => {
        setLoadingSave(false);
    };

    const handleModal = async () => {
        setOpenModal(!openModal);
    };


    const onSubmit = async (form: uploadFileIcetexType) => {
        try {
            setLoadingSave(true);
            const file = form.file[0];
            let formData: FormData = new FormData();
            formData.append("file", file);
            toast.info(
                "Te notificaremos por correo electrónico tan pronto como tengamos los resultados listos para ti."
            );
            const response = await api.uploadFileIcetexMasivian(formData);
            toast.info(response);
        } catch (error: any) {
            console.log(error);
            toast.error(error.response.data.message, toastOptions);
        } finally {
            setLoadingSave(false);
        }
    };

    const generateCertificate = async (form: generateCertificateType) => {
        try {
            setLoadingCerticate(true);
            const body: any = {
                startDate: form.startDate,
                endDate: form.endDate,
                tickler: form.tickler.toUpperCase()
            }
            const response = await api.generateCertificate(body);
            console.log(response);

            toast.info(response);
        } catch (error: any) {
            console.log(error);
            toast.error(error.response.data.message, toastOptions);
        } finally {
            setLoadingCerticate(false);
        }
    };

    return (
        <div>
            <Menu />
            <div className="d-flex justify-content-center mt-3">
                <h3>Subir archivo de correo de Masivian</h3>
            </div>
            <Container>
                <Formik
                    validationSchema={uploadFileIcetexSchema}
                    onSubmit={onSubmit}
                    initialValues={{
                        ticketNumber: "",
                        file: "",
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        setFieldValue,
                        errors,
                        isValid,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row style={{ paddingTop: "15px" }}>
                                <Col style={{ paddingBottom: "15px" }}>
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Form.Label>Subir arhivo</Form.Label>
                                        <Form.Control
                                            ref={fileInputRef}
                                            required
                                            type="file"
                                            name="file"
                                            onChange={(event) => {
                                                const file = event.currentTarget as HTMLInputElement;
                                                setFieldValue("file", file.files);
                                                // Actualizar el valor del campo "file" en Formik
                                            }}
                                            isInvalid={!!errors.file}
                                            accept=".csv"
                                        //disabled={isLoadingSave}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.file}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="d-flex justify-content-center">
                                <Button
                                    type="submit"
                                    disabled={isLoadingSave || values.file === "" || !isValid}
                                    variant="primary"
                                    size="lg"
                                    className="general-button"
                                >
                                    {!isLoadingSave ? "Subir archivo" : "Procesando archivo…"}
                                </Button>
                                {isLoadingSave && (
                                    <Button
                                        variant="primary"
                                        size="lg"
                                        className="general-button"
                                        onClick={() => {
                                            onResetUpload();
                                            fileInputRef.current && fileInputRef.current.click();
                                        }}
                                    >
                                        Cargar otro archivo
                                    </Button>
                                )}
                                <Button
                                    variant="primary"
                                    size="lg"
                                    className="general-button"
                                    onClick={handleModal}
                                >
                                    {!isLoadingCerticate
                                        ? "Generar certificados"
                                        : "Generando los cretificados…"}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>
            <Modal
                show={openModal}
                onHide={handleModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Formulario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Formik
                            validationSchema={generateCertificateSchema}
                            onSubmit={generateCertificate}
                            initialValues={{
                                startDate: "",
                                endDate: "",
                                tickler: '',
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                setFieldValue,
                                errors,
                                isValid,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Row style={{ paddingTop: "15px" }}>
                                        <Col style={{ paddingBottom: "5px" }} xs={12}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Ticket Jira</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name='tickler'
                                                    onChange={(event) => {
                                                        handleChange(event)
                                                    }}
                                                    value={values.tickler}
                                                    isInvalid={!!errors.tickler}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.tickler}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col style={{ paddingBottom: "5px" }} xs={6}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Fecha de creación inicio</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="date"
                                                    name="startDate"
                                                    onChange={handleChange}
                                                    value={values.startDate}
                                                    isInvalid={!!errors.startDate}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.startDate}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col style={{ paddingBottom: "5px" }} xs={6}>
                                            <Form.Group className="mb-1">
                                                <Form.Label>Fecha creación final</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="date"
                                                    name="endDate"
                                                    onChange={handleChange}
                                                    value={values.endDate}
                                                    isInvalid={!!errors.endDate}
                                                />
                                                <div style={{ minHeight: "3rem", color: "#dc3545" }}>
                                                    {errors.endDate}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <div className="d-flex justify-content-center">
                                        <Button
                                            type="submit"
                                            disabled={isLoadingCerticate || !isValid}
                                            variant="primary"
                                            size="lg"
                                            className="general-button"
                                        >
                                            Generar
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default UploadFileIcetex;
