import * as yup from "yup";
import { errors } from "../../const/const";

yup.setLocale({
    mixed: { default: "default", required: errors.REQUIRED },
    string: {
        email: errors.EMAIL,
        max: errors.MAX,
    },
    number: { integer: errors.INTEGER },
});