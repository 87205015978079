import "./common/config/yup";
import AuthContextProvider from './context/AuthContext/AuthContextProvider';
import AppRouter from './router/appRouter';

function App() {
  return (
    <AuthContextProvider>
      <AppRouter />
    </AuthContextProvider>
  );
}

export default App;