import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import * as config from '../common/config/config';
import * as utils from '../common/utils';
import { IAssignTurn, IAssignedTurnFNA } from "../interfaces/assignTurn.interface";
import { ISchedulesByGroup } from "../interfaces/date.interface";
import { BodyGenerateCertificateInterface } from "../interfaces/emailData.interface";
import { ErrorResponse } from "../interfaces/errorResponse.interface";
import { Token } from "../interfaces/jwt.interface";
import { IOffice } from "../interfaces/office.interface";
import { ITurnsActiveByDocumentRequest, ITurnsActiveByDocumentResponse } from "../interfaces/turnsActiveByDocument";
import { TicklerOrCampaignEnum } from "../pages/uploadFileIcetex/uploadFileIcetex.const";

export const uploadFileIcetexMasivian = async (formData: FormData): Promise<string> => {
    const url = `${config.BACKEND_URL}/api/v1/email-data`;
    const response = await axios.post<string>(url, formData, getAxiosHeader(true));
    return response.data;
}

export const getListEmailData = async (type: TicklerOrCampaignEnum): Promise<string[]> => {
    const url = `${config.BACKEND_URL_24}/api/v1/email-data/list/${type}`;
    const response = await axios.get<string[]>(url, getAxiosHeader(true));
    return response.data;
}

export const generateCertificate = async (body: BodyGenerateCertificateInterface): Promise<string> => {
    const url = `${config.BACKEND_URL_24}/api/v1/email-data/generate-certificate`;
    const response = await axios.post<string>(url, body, getAxiosHeader(true));
    return response.data;
}


export const getOffices = async (): Promise<IOffice[]> => {
    const url = `${config.BACKEND_URL}/api/v1/fna/offices`;
    const response = await axios.get<IOffice[]>(url, getAxiosHeader());
    return response.data;
}

export const getSchedulesByGroupCity = async (groupCity: string, subTurnTypeId: string | number, transactionId: string): Promise<ISchedulesByGroup[]> => {
    const payload = utils.getPayload();
    const agentUser: string = payload ? payload.username : '';
    const url = `${config.BACKEND_URL}/api/v1/fna/schedules-by-group?group=${groupCity}&subTurnTypeId=${subTurnTypeId}&showDaysWithoutSchedule=false&transactionId=${transactionId}&agentUser=${agentUser}`;
    const response = await axios.get<ISchedulesByGroup[]>(url, getAxiosHeader());
    return response.data;
}

export const assignTurnFNA = async (data: IAssignTurn): Promise<IAssignedTurnFNA> => {
    const payload = utils.getPayload();
    const agentUser: string = payload ? payload.username : '';
    data = { ...data, agentUser };
    const url = `${config.BACKEND_URL}/api/v1/fna/assign-turn`;
    const response = await axios.post<IAssignedTurnFNA>(url, data, getAxiosHeader());
    return response.data;
}

export const validateTurnsByUser = async (identificationNumber: string, identificationType: string): Promise<ITurnsActiveByDocumentResponse> => {
    const url = `${config.BACKEND_URL}/api/v1/fna/validate-turns?identificationNumber=${identificationNumber}&identificationType=${identificationType}`;
    const response = await axios.get<ITurnsActiveByDocumentResponse>(url, getAxiosHeader());
    return response.data;
}

export const deleteTurnByUser = async (identificationNumber: string, identificationType: string, agentUser?: string, transactionId?: string): Promise<IAssignedTurnFNA> => {
    const url = `${config.BACKEND_URL}/api/v1/fna/delete-turn`;
    const data: ITurnsActiveByDocumentRequest = {
        identificationNumber,
        identificationType,
        agentUser,
        transactionId
    };
    const response = await axios.post<IAssignedTurnFNA>(url, data, getAxiosHeader());
    return response.data;
}

export const login = async (username: string, password: string): Promise<AxiosResponse<Token | ErrorResponse, any>> => {
    const url = `${config.BACKEND_URL}/api/v1/user/login`;
    const response = await axios.post<Token | ErrorResponse>(url, {
        username,
        password
    }, {
        headers: {
            'Content-type': 'application/json'
        },
        validateStatus: (status) => status < 500,
    });
    return response;
}

export const getAxiosHeader = (file: boolean = false): AxiosRequestConfig => {
    const token = utils.generateJwtToken();
    const headers = {
        'Authorization': token
    };
    if (!file) {
        headers['Content-type'] = 'application/json';
    }
    return { headers };
}

