import * as yup from 'yup';
export const checkEmailIcetexSchema = yup.object().shape({
    documentId: yup.string()
        .matches(
            new RegExp(`^[0-9]+$`),
            "Solamente se permiten números"
        ),
    email: yup.string(),
    phone: yup.string(),
    tickler: yup.string(),
    startDate: yup.string().test('is-empty', 'Agrega fecha inicio', function (value) {
        const { endDate } = this.parent;
        if (endDate && !value) return false
        else return true;
    }),
    endDate: yup.string()
        .test('is-empty', 'Agrega fecha fin', function (value) {
            const { startDate } = this.parent;
            if (startDate && !value) return false
            else return true;
        })
        .test('is-greater', 'Debe ser posterior a la fecha de inicio ', function (value) {
            const { startDate } = this.parent;
            if (!value || !startDate) return true;
            return new Date(startDate) <= new Date(value);
        }),
});

export type checkEmailIcetexType = yup.InferType<typeof checkEmailIcetexSchema>;