import { useEffect, useState } from "react";
import { DefaultRowType, TableModel } from "../interfaces/table.interface";

const useTable = <T extends DefaultRowType>(
    defaultModels: TableModel<T>,
    onChange: (tableModel: TableModel<T>) => Promise<void>
) => {
    let {
        paginationModel,
        filterModel,
    } = defaultModels;


    const [currentPage, setCurrentPage] = useState<number>(paginationModel.page);


    /*     const blurRowsPerPage = useCallback(
            ({ target }: React.FocusEvent<HTMLInputElement>) => {
                setPaginationModel((beforePagination) => {
                    const newPaginationModel = {
                        ...beforePagination,
                        page: 0,
                    };
    
                    if ((beforePagination.rowsPerPage as unknown as string) === '') {
                        newPaginationModel.rowsPerPage = paginationModelParam.rowsPerPage;
                    }
    
                    onChange({
                        sortModel: sortModel,
                        paginationModel: newPaginationModel,
                        filterModel: filterModel,
                    });
                    return newPaginationModel;
                });
            },
            [paginationModelParam.rowsPerPage, onChange, sortModel, filterModel],
        ); */
    /* 
        const handlePage = (page: PaginationModel['page']) => async () => {
            setPaginationModel((beforePagination) => {
                const newPaginationModel = { ...beforePagination, page };
                onChange({
                    paginationModel: newPaginationModel,
                    filterModel: filterModel,
                });
                return newPaginationModel;
            });
        } */


    //Use effect to update the pagination model when the rows per page change
    /*     useEffect(() => {
            setPaginationModel((beforePagination) => {
                const newPaginationModel = {
                    ...beforePagination,
                    rowsPerPage: paginationModelParam.rowsPerPage,
                };
                return newPaginationModel;
            });
        }, [paginationModelParam.rowsPerPage]); */

    /*     useEffect(() => {
            setPaginationModel((beforePagination) => {
                const newPaginationModel = {
                    ...beforePagination,
                    page: paginationModelParam.page,
                };
                return newPaginationModel;
            });
        }, [paginationModelParam.page]); */

    /*     useEffect(() => {
            setPaginationModel((beforePagination) => {
                const newPaginationModel = {
                    ...beforePagination,
                    rowsCount: paginationModelParam.rowsCount,
                };
                return newPaginationModel;
            });
        }, [paginationModelParam.rowsCount]); */

    useEffect(() => {
        paginationModel.page = currentPage;

        onChange({
            paginationModel,
            filterModel,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterModel, currentPage]);

    return {
        currentPage,
        setCurrentPage,
        /*         rowsPerPageProps: {
                    onChange: handleRowsPerPage,
                    onBlur: blurRowsPerPage,
                }, */
    };
};

export default useTable;
