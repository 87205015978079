export const loadState = <T = any>(key: string) => {
    try {
        const value = localStorage.getItem(key);
        if (value === null) return undefined;

        return JSON.parse(value) as T;
    } catch (err) {
        console.error(err);
        return undefined;
    }
};

export const saveState = (key: string, state?: any) => {
    try {
        if (state === undefined || state === null) {
            localStorage.removeItem(key);
        } else {
            const serialState = JSON.stringify(state);
            localStorage.setItem(key, serialState);
        }
    } catch (err) {
        console.error(err);
    }
};

export const removeState = (key: string) => {
    try {
        localStorage.removeItem(key);
    } catch (err) {
        console.error(err);
    }
};

export const removeAll = (exceptions?: string[]) => {
    try {
        const newExceptions = exceptions || [];
        const copy = newExceptions.map((exception) => ({
            key: exception,
            value: loadState(exception),
        }));

        localStorage.clear();

        copy.forEach(({ key, value }) => {
            saveState(key, value);
        });
    } catch (err) {
        console.error(err);
    }
};
