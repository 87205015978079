export const documentTypes = [
    {
        label: 'Cédula de Ciudadanía',
        value: 'CC'
    },
    {
        label: 'Cédula de Extranjería',
        value: 'CE'
    },
    {
        label: 'Pasaporte',
        value: 'PA'
    },
    {
        label: 'Nit',
        value: 'NIT'
    },
];