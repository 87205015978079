import { Table as BootstrapTable, Button, Placeholder } from "react-bootstrap";
import { BasicTableProps, DefaultRowType } from "../../interfaces/table.interface";
import TableFooter from "./footer";
import "./styles.css";

/**
 * This component is a basic table based on the BoostrapTable, receives the columns, rows to render and additional props from the BasicTableProps interface
 */
const Table = <T extends DefaultRowType>({
    columns,
    rows,
    currentPage,
    setCurrentPage,
    totalPages,
    loading,
    rowsPerPage,
    totalRows,
    onDownloadExcel,
    downloadDisabled,
    loadingExcelDownload,
}: BasicTableProps<T>) => {

    return (
        <>
            {totalPages ?
                <>
                    <BootstrapTable striped hover>
                        <thead>
                            {!loading ? (
                                <tr>
                                    {columns.map((column, index) => (
                                        <th key={index}>{column.headerName}</th>
                                    ))}
                                </tr>) : <tr>
                                <th>Cargando ...</th>
                            </tr>}
                        </thead>
                        <tbody>
                            {loading ? (
                                [...Array(1)].map((_, index) => (
                                    <tr key={index}>
                                        {columns.map((_, columnIndex) => (
                                            <td key={columnIndex}>
                                                <Placeholder animation="glow">&nbsp;</Placeholder>
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                rows.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {columns.map((column, columnIndex) => {
                                            if (column.render) {
                                                return <td key={columnIndex}>{column.render(row)}</td>
                                            }
                                            return <td key={columnIndex}>{row[column.field]}</td>
                                        })}
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </BootstrapTable>
                    <div className="d-flex justify-content-between">
                        <div className="mx-auto">
                            <TableFooter
                                pages={totalPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalRows={totalRows}
                            />
                        </div>
                        {
                            !!onDownloadExcel && !!downloadDisabled &&
                            <div className="ml">
                                <Button
                                    onClick={() => onDownloadExcel()}
                                    disabled={downloadDisabled() || loadingExcelDownload}
                                >{loadingExcelDownload ? 'Descargando ...' : 'Descargar'}</Button>
                            </div>
                        }
                    </div>
                </> :
                <div>
                    <div style={{ height: '1px' }} className="d-flex w-100 pt-3 pb-2 mb-1 border-bottom"></div>
                    <p>No hay datos para mostrar</p>
                </div>

            }



        </>
    )
};

export default Table;