import { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { useLocation } from 'react-router-dom';
import logo from '../common/assets/logo.png';
import * as config from '../common/config/config';
import * as utils from '../common/utils';
import { AuthContext } from '../context/AuthContext';

const Menu = () => {
  const location = useLocation();
  const payload = utils.getPayload();
  const { menus, title } = useContext(AuthContext);
  const [username, setUsername] = useState<string>(payload?.username ?? '');

  const closeSession = () => {
    localStorage.removeItem(config.USER_KEY_LOCALSTORAGE);
    window.location.reload();
  }

  return (
    <Navbar bg="primary" data-bs-theme="dark">
      <Container>
        <Navbar.Brand><img src={logo} alt="Logo" width={70} />{title}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav className="me-auto">
            {menus?.map(item => {
              return (<Nav.Link key={item.title} active={location.pathname.toLowerCase().includes(item.link)} href={item.link}>{item.title}</Nav.Link>)
            })}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <Navbar.Text>
              Usuario:
            </Navbar.Text>
            <NavDropdown title={username} drop="down-centered" align={{ lg: 'end' }}>
              <NavDropdown.Item onClick={() => { closeSession() }}>Cerrar Sesión</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Menu;