
import { PropsWithChildren, useCallback, useState } from "react";
import { loadState, saveState } from "../../common/utils/localStorage";
import { Token } from "../../interfaces/jwt.interface";
import AuthContext from "./AuthContext";

export type AuthContextProviderProps = PropsWithChildren<unknown>;

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const localAuth = loadState<Token>("auth");

  const [auth, setAuth] = useState<Token | undefined>(localAuth);

  const login = useCallback((auth: Token) => {
    setAuth(auth);
    saveState("auth", auth);
  }, []);

  const logout = useCallback(() => {
    sessionStorage.clear();
  }, []);

  const value = {
    ...auth,
    logout,
    login,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
