import * as lodash from 'lodash';
import moment from 'moment';
import 'moment/locale/es';
import React, { useEffect, useRef, useState } from "react";
import { Button, Collapse, Form, Modal } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import Select from "react-select";
import { useTimer } from 'react-timer-hook';
import { ToastOptions, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { v4 as uuidv4 } from 'uuid';
import * as api from '../api';
import * as config from '../common/config/config';
import { documentTypes } from '../common/const/fna';
import * as utils from '../common/utils';
import Menu from "../components/menu";
import { IAssignTurn } from "../interfaces/assignTurn.interface";
import { IScheduleHours } from "../interfaces/date.interface";
import { IOffice, ISubTypeTurn, ITurnType } from "../interfaces/office.interface";
import { ITurnsActiveByDocumentResponse } from "../interfaces/turnsActiveByDocument";
import OfficeSchedule from "./officeSchedule";
const poup = withReactContent(Swal);

const TurnForm: React.FC = () => {
  const genders: string[] = ['Femenino', 'Masculino', 'Otro'];
  const maxPhoneLength: number = 10;
  const cityGroupDefault: string = 'Bogotá';
  const emailDomains: string[] = ['@gmail.com', '@hotmail.com', '@outlook.com', '@yahoo.com', '@live.com'];
  const [offices, setOffices] = useState<IOffice[]>([]);
  const [citiesGroup, setCitiesGroup] = useState<string[]>([]);
  const [typesTurnsByCityGroup, setTypesTurnsByCityGroup] = useState<ITurnType[]>([]);
  const [selectedCityGroup, setSelectedCityGroup] = useState<string | null>(null);
  const [selectedOffice, setSelectedOffice] = useState<IOffice | null>(null);
  const [selectedTurnType, setSelectedTurnType] = useState<ITurnType | null>(null);
  const [selectedSubTurnType, setSelectedSubTurnType] = useState<ISubTypeTurn | null>(null);
  const [selectedSchedule, setSelectedSchedule] = useState<IScheduleHours | null>(null);
  const [selectedTypeDocument, setSelectedTypeDocument] = useState<string | undefined>(documentTypes[0].value);
  const [identificationNumber, setIdentificationNumber] = useState<string | undefined>(undefined);
  const [phone, setPhone] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [emailIsValid, setEmailIsValid] = useState<boolean>(true);
  const [personName, setPersonName] = useState<string | undefined>(undefined);
  const [selectedGender, setSelectedGender] = useState<string>(genders[0]);
  const [isLoadingSave, setLoadingSave] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [sedeSearch, setSedeSearch] = useState<string>('');
  const [transactionId, setTransactionId] = useState<string>(uuidv4());
  const [intervalTransactionId, setIntervalTransactionId] = useState<number | null>(null);
  const [selectedTransactionId, setSelectedTransactionId] = useState<string | null>(null);
  const [authorizationChecked, setAuthorizationChecked] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const { seconds, minutes, restart } = useTimer({ expiryTimestamp: moment().add(config.INTERVAL_TIME_REFRESH_TRANSACTION, 'milliseconds').toDate(), autoStart: true });
  //change page tittle
  document.title = config.APP_TITTLE;

  const toastOptions: ToastOptions = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored"
  };

  useEffect(() => {
    const loadInitialData = async () => {
      let offices: IOffice[] = [];
      try {
        offices = await api.getOffices();
      } catch (error) {
        toast.error('Ocurrió un error inesperado al obtener las oficinas, por favor refresque el navegador.', toastOptions);
      }
      setOffices(offices);
      const cities = lodash.uniq(offices.map(office => office.group));
      setCitiesGroup(cities);
      //set bogota city
      setSelectedCityGroup(cityGroupDefault);
      const turnTypes: ITurnType[] = lodash.uniqBy(offices.filter(office => office.group === cityGroupDefault).flatMap(office => office.turnTypes), x => x.name);
      setTypesTurnsByCityGroup(turnTypes);
    }

    loadInitialData();
    const interval = window.setInterval(() => generateTransactionId(), config.INTERVAL_TIME_REFRESH_TRANSACTION);
    setIntervalTransactionId(interval);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleCityGroup = (cityGroup?: string) => {
    setSelectedCityGroup(cityGroup ?? null);
    setSelectedTurnType(null);
    setSelectedSubTurnType(null);
    const turnTypes = getTypesTurnsByCityGroupSelected(cityGroup);
    setTypesTurnsByCityGroup(turnTypes);
    setSelectedSchedule(null);
    setSelectedOffice(null);
  };

  const handleTurnTypeChange = (subTurnTypeId?: string | number) => {
    const turnType = typesTurnsByCityGroup.find((typeTurn) => typeTurn.subTypeTurn?.find(subTypeTurn => subTypeTurn.id == subTurnTypeId));
    const subTurnType = turnType?.subTypeTurn?.find(subTypeTurn => subTypeTurn.id == subTurnTypeId);
    setSelectedTurnType(turnType ?? null);
    setSelectedSubTurnType(subTurnType ?? null);
    setModalOpen(!!turnType);
    setSelectedSchedule(null);
    setSelectedOffice(null);
    setSelectedTransactionId(transactionId);
  };

  const handleScheduleChange = (officeId: string | number | null, schedule: IScheduleHours | null) => {
    const officeFound = offices.find(office => office.id == officeId);
    setSelectedOffice(officeFound ?? null);
    setSelectedSchedule(schedule);
    if (officeId && schedule) {
      setModalOpen(false);
    }
  }

  const handleEmailDomain = (domain: string) => {
    let emailTemp = email?.trim() || '';
    emailTemp = emailTemp + domain;
    setEmail(emailTemp);
    setEmailIsValid(emailTemp ? utils.validateEmailAddressFormat(emailTemp) : false);
  }

  const onBlurEmailValid = () => {
    setEmailIsValid(email ? utils.validateEmailAddressFormat(email) : false);
  }

  const handleSave = async () => {
    setLoadingSave(true);
    const data: IAssignTurn = {
      officeId: selectedOffice?.id,
      subTurnTypeId: selectedSubTurnType?.id,
      identification: identificationNumber ?? '',
      identificationType: selectedTypeDocument ?? '',
      gender: selectedGender,
      phone: phone,
      name: personName?.trim(),
      email: email?.trim(),
      dateRequest: selectedSchedule?.dateRequest,
      transactionId: selectedTransactionId ?? transactionId,
      recaptcha: recaptchaToken
    };
    try {
      const turnsActive: ITurnsActiveByDocumentResponse = await api.validateTurnsByUser(data.identification, data.identificationType);
      if (turnsActive.hasTurns) {
        await poup.fire({
          titleText: 'Asignación de turno',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: 'green',
          confirmButtonText: 'Reagendar Turno',
          showCancelButton: true,
          cancelButtonColor: 'red',
          cancelButtonText: 'Cerrar',
          allowOutsideClick: false,
          text: `El usuario ya tiene un turno asignado en '${turnsActive.companyOfficeDescription}' en el horario: ${turnsActive.dateRequest}. ¿Está seguro que sea reagendar?`,
          willClose: () => setLoadingSave(false),
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            await deleteAndAssignTurn(data);
            return true;
          }
        });
      } else {
        await assignTurn(data);
      }
    } catch (error) {
      console.log(error);
      toast.error('Ocurrió un error inesperado al asignar el turno, por favor intente nuevamente.', toastOptions);
    } finally {
      setLoadingSave(false);
    }
  };

  const resetForm = () => {
    setSelectedTurnType(null);
    setSelectedSubTurnType(null);
    setSelectedGender(genders[0]);
    setIdentificationNumber(undefined);
    setSelectedTypeDocument(documentTypes[0].value);
    setSelectedSchedule(null);
    setSelectedOffice(null);
    setSedeSearch('');
    setPhone(undefined);
    setPersonName(undefined);
    setEmail(undefined);
    setSelectedCityGroup(cityGroupDefault);
    setSelectedTransactionId(null);
    setAuthorizationChecked(false);
    clearIntervalTransactionId();
    //reiniciar estado el captcha al resetear el formulario
    recaptchaRef?.current?.reset();
    setRecaptchaToken(null);
  }

  const assignTurn = async (data: IAssignTurn) => {
    try {
      const response = await api.assignTurnFNA(data);
      if (response.success) {
        const scheduleText = `${lodash.capitalize(moment(selectedSchedule?.dateRequest, 'YYYY-MM-DD').format('DD/MM/YYYY'))} (${selectedSchedule?.description})`;
        const sedeText: string = selectedOffice ? `${selectedOffice.name} - ${selectedOffice.description}` : '';
        toast.success(<div>Turno programado correctamente.<br />🕒Horario: {scheduleText}.<br />📍Sede: {sedeText}.</div>, toastOptions);
        resetForm();
      } else {
        toast.error(response.message, toastOptions);
      }
    } catch (error) {
      console.log(error);
      toast.error('Ocurrió un error inesperado al asignar el turno, por favor intente nuevamente.', toastOptions);
    } finally {
      setLoadingSave(false);
    }
  }

  const deleteAndAssignTurn = async (data: IAssignTurn): Promise<void> => {
    await api.deleteTurnByUser(data.identification, data.identificationType);
    await assignTurn(data);
  }


  const getTypesTurnsByCityGroupSelected = (cityGroup?: string): ITurnType[] => {
    const turnTypes: ITurnType[] = cityGroup ? lodash.uniqBy(offices.filter(office => office.group === cityGroup).flatMap(office => office.turnTypes), x => x.name) : [];
    return turnTypes;
  }

  const generateTransactionId = () => {
    restart(moment().add(config.INTERVAL_TIME_REFRESH_TRANSACTION, 'milliseconds').toDate(), true);
    setTransactionId(uuidv4());
  }

  const clearIntervalTransactionId = () => {
    if (intervalTransactionId) {
      clearInterval(intervalTransactionId);
    }
    generateTransactionId();
    const interval = window.setInterval(() => clearIntervalTransactionId(), config.INTERVAL_TIME_REFRESH_TRANSACTION);
    setIntervalTransactionId(interval);
  }

  return (
    <div>
      <Menu />
      <Container>
        {/*<Row>
          <div className="d-flex justify-content-end">
            <span>Duración Transacción:&nbsp;&nbsp;</span><span>{minutes < 10 ? '0' + minutes : minutes}:{seconds < 10 ? '0' + seconds : seconds}</span>
          </div>
  </Row>*/}
        <Row>
          <div className="d-flex justify-content-center mt-3">
            <h3>Asignación de turnos FNA</h3>
          </div>
        </Row>
        <Row style={{ paddingTop: '15px' }}>
          <Col style={{ paddingBottom: '15px' }}>
            <label>Selecciona la ciudad:</label>
            <Select
              options={citiesGroup.map((city) => ({
                label: city,
                value: city,
              }))}
              onChange={(newValue) => { handleCityGroup(newValue?.value ?? '') }}
              placeholder="Seleccione una ciudad"
              noOptionsMessage={() => 'No se encontró ninguna ciudad.'}
              value={{
                label: selectedCityGroup,
                value: selectedCityGroup,
              }}
            />
          </Col>
          <Col style={{ paddingBottom: '15px' }}>
            <label>Selecciona un tipo de turno:</label>
            <Select
              options={typesTurnsByCityGroup.map((turnType) => ({
                label: turnType.name,
                options: turnType.subTypeTurn?.map((subTurnType) => ({
                  label: subTurnType.name,
                  value: subTurnType.id
                }))
              }))}
              onChange={(newValue) => { handleTurnTypeChange(newValue?.value) }}
              placeholder="Seleccione un tipo de turno"
              value={selectedSubTurnType ? { label: selectedSubTurnType.name, value: selectedSubTurnType.id } : null}
              noOptionsMessage={() => 'No se encontró ningún tipo de turno.'}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingBottom: '15px' }}>
            <label>Horario:</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder={selectedSchedule ? `${lodash.capitalize(moment(selectedSchedule.dateRequest, 'YYYY-MM-DD').format('dddd - DD/MM/YYYY'))} (${selectedSchedule.description})` : "Seleccione un horario..."}
                disabled
              />
              <span className="input-group-text span-icon" onClick={() => {
                if (selectedTurnType) {
                  setModalOpen(true);
                  setSelectedTransactionId(transactionId);
                }
              }}><i className="bi bi-calendar4-week"></i></span>
            </div>
          </Col>
          <Col style={{ paddingBottom: '15px' }}>
            <label>Sede:</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder={selectedOffice ? `${selectedOffice.name} - ${selectedOffice.description}` : ''}
                disabled
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Collapse in={!!selectedSchedule}>
            <div>
              <Row>
                <Col style={{ paddingBottom: '15px' }}>
                  <label>Tipo de documento:</label>
                  <Select
                    options={documentTypes}
                    onChange={(newValue) => { setSelectedTypeDocument(newValue?.value) }}
                    placeholder="Seleccione el tipo de documento"
                    isClearable={false}
                    isSearchable={false}
                    value={{
                      label: documentTypes.find(documentType => documentType.value === selectedTypeDocument)?.label,
                      value: selectedTypeDocument,
                    }}
                  />
                </Col>
                <Col style={{ paddingBottom: '15px' }}>
                  <label>Número de documento:</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"Ingrese el número de documento..."}
                      onChange={(event) => {
                        if (event?.target?.value !== '' && !utils.isNumber(event?.target?.value)) {
                          event.preventDefault();
                          return;
                        }
                        setIdentificationNumber(event?.target?.value);
                      }}
                      value={identificationNumber ?? ''}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col style={{ paddingBottom: '15px' }}>
                  <label>Nombre:</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"Ingrese el nombre..."}
                      onChange={(event) => {
                        setPersonName(event?.target?.value);
                      }}
                      value={personName ?? ''}
                    />
                  </div>
                </Col>
                <Col style={{ paddingBottom: '15px' }}>
                  <label>Email:</label>
                  {email && !email.includes('@') ?
                    (<div className="suggestion-box">
                      {emailDomains.map(email => (<span className="suggestion" onClick={() => handleEmailDomain(email)}>{email}</span>))}
                    </div>)
                    : null}
                  <div className="input-group">
                    <input
                      type="text"
                      className={emailIsValid ? 'form-control' : 'form-control is-invalid'}
                      placeholder={"Ingrese el email..."}
                      onChange={(event) => {
                        setEmail(event?.target?.value);
                      }}
                      value={email ?? ''}
                      autoComplete="off"
                      onBlur={() => onBlurEmailValid()}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col style={{ paddingBottom: '15px' }}>
                  <label>Celular:</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"Ingrese el número de celular..."}
                      onChange={(event) => {
                        if ((event?.target?.value !== '' && !utils.isNumber(event?.target?.value)) || event.currentTarget.value.length > maxPhoneLength) {
                          event.preventDefault();
                          return;
                        }
                        setPhone(event?.target?.value)
                      }}
                      value={phone ?? ''}
                    />
                  </div>
                </Col>
                <Col style={{ paddingBottom: '15px' }}>
                  <div key="default-radio" className="mb-3">
                    <Row>
                      <Col style={{ paddingBottom: '8px' }}><label>Género:</label></Col>
                    </Row>
                    <Row>
                      <Col>{genders.map((value, index) => {
                        return (
                          <Form.Check
                            inline
                            label={value}
                            value={value}
                            name="gender"
                            type='radio'
                            checked={selectedGender == value}
                            key={`radio-${value.toLowerCase()}`}
                            onClick={(event) => { setSelectedGender(event?.currentTarget?.value) }}
                            onChange={() => { }}
                            className="radio-border"
                          />
                        )
                      })}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col style={{ paddingBottom: '15px' }}>
                  <Form.Check type="checkbox" id="checkbox">
                    <Form.Check.Input type="checkbox" className='check-border' onChange={(event) => setAuthorizationChecked(event.currentTarget.checked)} checked={authorizationChecked} />
                    <Form.Check.Label><Link to="https://www.fna.gov.co/atencion-ciudadana/condiciones-y-politicas/politica-tratamiento-datos-personales" target="_blank">He leído y acepto la autorización de tratamiento de datos y los términos y condiciones del servicio.</Link></Form.Check.Label>
                  </Form.Check>
                </Col>
              </Row>
              {config.RECAPTCHA_ENABLED ? (
                <Row>
                  <Col style={{ paddingBottom: '15px' }}>
                    <ReCAPTCHA
                      sitekey={config.RECAPTCHA_SITE_KEY}
                      onChange={(token) => setRecaptchaToken(token)}
                      hl='es-419'
                      onExpired={() => setRecaptchaToken(null)}
                      onError={() => setRecaptchaToken(null)}
                      ref={recaptchaRef}
                    />
                  </Col>
                </Row>
              ) : null}
              <div className="d-flex justify-content-center">
                <Button style={{ marginBottom: '50px' }}
                  variant="primary"
                  disabled={isLoadingSave || !phone || phone.length < maxPhoneLength || !identificationNumber || !selectedSchedule || !setSelectedTurnType || !authorizationChecked || !personName || !email || !emailIsValid || (!recaptchaToken && config.RECAPTCHA_ENABLED)}
                  onClick={!isLoadingSave ? handleSave : undefined}
                  size="lg"
                >
                  {!isLoadingSave ? 'Asignar turno' : 'Asignando…'}
                </Button>
              </div>
            </div>
          </Collapse>
        </Row>
      </Container>
      <div>
        <Modal
          show={isModalOpen}
          onHide={() => {
            setModalOpen(false);
            setSedeSearch('');
            //Al cerrar el modal, si no tiene seleccionado un horario, se deselecciona el tipo de turno.
            if (!selectedSchedule) {
              setSelectedTurnType(null);
              setSelectedSubTurnType(null);
            }
          }}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="input-group">
                <div style={{ paddingRight: '15px' }}>Horarios Disponibles:</div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Filtrar sede..."
                  onChange={(event) => setSedeSearch(event.target.value)}
                />
                <span className="input-group-text"><i className="bi bi-search"></i></span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <OfficeSchedule selectedCityGroup={selectedCityGroup} selectedSubTurnType={selectedSubTurnType} handleScheduleChange={handleScheduleChange} sedeSearch={sedeSearch} transactionId={transactionId} />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default TurnForm;