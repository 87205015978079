import { es } from 'date-fns/locale';
import { ToastOptions } from 'react-toastify';
export const errors = {
    INVALID: "El valor es invalido",
    REQUIRED: "El valor es requerido",
    EMAIL: "El email no es valido",
    MAX: "Excedio el maximo de caracteres",
    INTEGER: "No es un valor numerico",
};
export const DATE_FORMAT = 'PP, hh:mm aaa';
export const COMMON_OPTIONS = { locale: es };

export const defaulttoastOptions: ToastOptions = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored"
};
