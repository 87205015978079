import { jwtDecode } from "jwt-decode";
import { Payload } from "../../interfaces/jwt.interface";
import * as config from '../config/config';
export const generateAuthToken = (username: string, password: string) => {
    const token = btoa(`${username}:${password}`);
    return `Basic ${token}`;
}

export const generateJwtToken = () => {
    const token = localStorage.getItem(config.USER_KEY_LOCALSTORAGE);
    return `Bearer ${token}`;
}

export const isNumber = (str: string): boolean => {
    const regex = /^\d+$/;
    return !!str && regex.test(str);
}

export const checkLogin = (): boolean => {
    const payload = getPayload();
    const currentTime = Date.now() / 1000;
    return !!payload?.exp && payload.exp >= currentTime;
}

export const getPayload = (): Payload | null => {
    let payload: Payload | null = null;
    const token = localStorage.getItem(config.USER_KEY_LOCALSTORAGE);
    if (token) {
        payload = jwtDecode<Payload>(token);
    }
    return payload;
}

/**
 * Validate if a email address has a correct format.
 * @param email
 * @returns true if the email address  is valid, otherwise false.
 */
export const validateEmailAddressFormat = (email: string): boolean => {
    const validEmailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/gi;
    if (!email) return false;
    return validEmailRegex.test(email);
};
