import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastOptions, toast } from 'react-toastify';
import * as api from '../api';
import * as config from '../common/config/config';
import { AuthContext } from '../context/AuthContext';
import { ErrorResponse } from '../interfaces/errorResponse.interface';
import { Token } from '../interfaces/jwt.interface';
import '../styles/login.css';
import { Button } from 'react-bootstrap';

const Login = () => {
  //change page tittle
  document.title = 'Iniciar Sesión';
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoadingLogin, setLoadingLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const toastOptions: ToastOptions = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored"
  };

  const handleLogin = async () => {
    try {
      setLoadingLogin(true);
      const response = await api.login(username, password);
      if (response.status >= 200 && response.status < 300) {
        const data = response.data as Token;
        localStorage.setItem(config.USER_KEY_LOCALSTORAGE, data.token);
        login(data);
        navigate(data.menus[0].link);
      } else {
        const data = response.data as ErrorResponse;
        toast.error(data.message, toastOptions);
      }
    } catch (error) {
      console.error(error);
      toast.error('Ocurrió un error inesperado al tratar de iniciar sesión, intente nuevamente.', toastOptions);
    } finally {
      setLoadingLogin(false);
    }
  };

  return (
    <div className='container-logo'>
      <div className="container container-login">
        <div className="row row-login">
          <div className="col-md-10">
            <h2>Iniciar sesión</h2>
            <form>
              <div className="form-group form-group-login">
                <label htmlFor="username">Usuario:</label>
                <input
                  type="text"
                  id="username"
                  className="form-control form-control-login"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group form-group-login">
                <label htmlFor="password">Contraseña:</label>
                <div style={{ position: 'relative' }}>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    className="form-control form-control-login"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key?.toUpperCase() === 'ENTER') {
                        handleLogin();
                      }
                    }}
                  />
                  <Button
                    style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', backgroundColor: 'transparent', border: 'none' }}
                    className={showPassword ? "bi bi-eye-slash" : "bi bi-eye"}
                    onClick={() => setShowPassword(!showPassword)}
                    variant="link"
                  ></Button>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary btn-primary-login"
                  disabled={isLoadingLogin}
                  onClick={!isLoadingLogin ? handleLogin : undefined}
                >
                  {!isLoadingLogin ? 'Iniciar sesión' : 'Iniciando sesión…'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;