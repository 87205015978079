import "moment/locale/es";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import Select from "react-select";
import { ToastOptions, toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import * as api from "../api";
import { documentTypes } from "../common/const/fna";
import * as utils from "../common/utils";
import Menu from "../components/menu";

const CancelTurnForm: React.FC = () => {

  const [selectedTypeDocument, setSelectedTypeDocument] = useState<string | undefined>(documentTypes[0].value);
  const [identificationNumber, setIdentificationNumber] = useState<string | undefined>(undefined);
  const [isLoadingCancel, setLoadingCancel] = useState(false);
  const [transactionId, setTransactionId] = useState<string>(uuidv4());
  const [authorizationChecked, setAuthorizationChecked] = useState<boolean>(false);
  //change page tittle
  document.title = "Cancelación Turno";

  const toastOptions: ToastOptions = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored",
  };

  const handleCancelTurn = async () => {
    setLoadingCancel(true);
    if (identificationNumber && selectedTypeDocument) {
      try {
        const payload = utils.getPayload();
        const agentUser: string = payload?.username ?? '';
        const response = await api.deleteTurnByUser(
          identificationNumber,
          selectedTypeDocument,
          agentUser,
          transactionId
        );
        if (response.success) {
          toast.success(response.message, toastOptions);
        } else {
          toast.error(response.message, toastOptions);
        }
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error("Ocurrió un error inesperado al cancelar el turno, por favor intente nuevamente.", toastOptions);
      } finally {
        setLoadingCancel(false);
      }
    }
  };

  const resetForm = () => {
    setIdentificationNumber(undefined);
    setSelectedTypeDocument(documentTypes[0].value);
    setTransactionId(uuidv4());
    setAuthorizationChecked(false);
  };

  return (
    <div>
      <Menu />
      <div className="d-flex justify-content-center mt-3">
        <h3>Cancelación de turnos FNA</h3>
      </div>
      <Container>
        <Row>
          <Col style={{ paddingBottom: "15px", paddingTop: '15px' }}>
            <label>Tipo de documento:</label>
            <Select
              options={documentTypes}
              onChange={(newValue) => {
                setSelectedTypeDocument(newValue?.value);
              }}
              placeholder="Seleccione el tipo de documento"
              isClearable={false}
              isSearchable={false}
              value={{
                label: documentTypes.find(documentType => documentType.value === selectedTypeDocument)?.label,
                value: selectedTypeDocument,
              }}
            />
          </Col>
          <Col style={{ paddingBottom: "15px", paddingTop: '15px' }}>
            <label>Número de documento:</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder={"Ingrese el número de documento..."}
                onChange={(event) => {
                  if (
                    event?.target?.value !== "" &&
                    !utils.isNumber(event?.target?.value)
                  ) {
                    event.preventDefault();
                    return;
                  }
                  setIdentificationNumber(event?.target?.value);
                }}
                value={identificationNumber ?? ""}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingBottom: '15px' }}>
            <Form.Check type="checkbox" id="checkbox">
              <Form.Check.Input type="checkbox" className='check-border' onChange={(event) => setAuthorizationChecked(event.currentTarget.checked)} checked={authorizationChecked} />
              <Form.Check.Label><Link to="https://www.fna.gov.co/atencion-ciudadana/condiciones-y-politicas/politica-tratamiento-datos-personales" target="_blank">He leído y acepto la autorización de tratamiento de datos y los términos y condiciones del servicio.</Link></Form.Check.Label>
            </Form.Check>
          </Col>
        </Row>
        <div className="d-flex justify-content-center">
          <Button
            style={{ marginBottom: "50px" }}
            variant="primary"
            disabled={isLoadingCancel || !identificationNumber || !authorizationChecked}
            onClick={!isLoadingCancel ? handleCancelTurn : undefined}
            size="lg"
          >
            {!isLoadingCancel ? "Cancelar turno" : "Cancelando…"}
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default CancelTurnForm;