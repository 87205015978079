import { useContext } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { AuthContext } from '../context/AuthContext';
import CancelTurnForm from '../pages/cancelTurnForm';
import CheckEmailIcetex from '../pages/checkEmailicetex/checkEmailIcetex';
import Login from "../pages/login";
import TurnForm from '../pages/turnForm';
import UploadFileIcetex from '../pages/uploadFileIcetex/uploadFileIcetex';
import * as utils from './../common/utils';
import ProtectedRoute from "./protectedRoute";

const AppRouter = () => {
    const { menus } = useContext(AuthContext);

    // const ComponentArray = [
    //     { key: 'asignar-turno', component: TurnForm },
    //     { key: 'cancelar-turno', component: CancelTurnForm },
    //     { key: 'subir-archivo', component: UploadFileIcetex },
    //     { key: 'consultar', component: CheckEmailIcetex }
    // ];

    // const renderComponent = (key: string) => {
    //     const componenteObj = ComponentArray.find(item => item.key === key);
    //     if (componenteObj) {
    //         return componenteObj.component;
    //     }
    //     return <Navigate to='/' replace />; // o algún manejo de error si la clave no se encuentra
    // };
    const AppWrapper = ({ children }) => {
        return (
            <div className='app-wrapper'>
                {children}
            </div>
        );
    };

    return (
        <AppWrapper>
            <Router>
                <Routes>
                    <Route path="/" element={!utils.checkLogin() ? <Navigate to="/login" replace /> : <Navigate to={menus?.[0]?.link || '/'} replace />} />
                    <Route path="login" element={!utils.checkLogin() ? <Login /> : <Navigate to={menus?.[0]?.link || '/login'} replace />} />
                    {/* {menus?.map(item => {
                    return (
                        <Route path={item.link} element={
                            <ProtectedRoute>
                                <>{renderComponent(item.link)}</>
                            </ProtectedRoute>
                        } />)
                })} */}
                    <Route path="asignar-turno" element={
                        <ProtectedRoute>
                            <TurnForm />
                        </ProtectedRoute>
                    } />
                    <Route path="cancelar-turno" element={
                        <ProtectedRoute>
                            <CancelTurnForm />
                        </ProtectedRoute>
                    } />
                    <Route path="subir-archivo" element={
                        <ProtectedRoute>
                            <UploadFileIcetex />
                        </ProtectedRoute>
                    } />
                    <Route path="consultar" element={
                        <ProtectedRoute>
                            <CheckEmailIcetex />
                        </ProtectedRoute>
                    } />
                </Routes>
                <ToastContainer />
                {/* <Footer /> */}
            </Router>
        </AppWrapper>
    )
}
export default AppRouter;