import { DefaultRowType, FilterModel, TableModel } from "../../interfaces/table.interface";

export const createFilterStructure = <T extends DefaultRowType>(
    field: FilterModel<T>['field'],
    type: FilterModel<T>['type'],
    value?: FilterModel<T>['value'],
    table?: FilterModel<T>['table'],
) => {
    if (value) return { field, value, type, table };
};


export const createPayload = <T extends DefaultRowType>({
    filterModel,
    paginationModel,
}: TableModel<T>) =>
    `?${filterModel && filterModel.length > 0
        ? `filter=${JSON.stringify(filterModel)}&`
        : ''
    }${paginationModel
        ? `limit=${paginationModel.limit}&page=${paginationModel.page}`
        : ''
    }`;

export const createFilterPayload = <T extends DefaultRowType>(filterModel?: FilterModel<T>[]) =>
    `?${filterModel && filterModel.length > 0
        ? `filter=${JSON.stringify(filterModel)}&`
        : ''
    }`;