import { jsPDF } from "jspdf";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import logo from '../../common/assets/logo-min.png';
import { customFormatDate } from "../../common/utils/date";
import { EmailicetexDataDetail } from "../../interfaces/emailData.interface";

export interface CustomModalProps {
    showModal: boolean;
    setShowMoadal: React.Dispatch<React.SetStateAction<boolean>>;
    emailDataDetail: EmailicetexDataDetail | undefined;
}

const CustomModal = ({
    showModal,
    setShowMoadal,
    emailDataDetail,
}: CustomModalProps) => {

    const [isEmail, setIsEmail] = useState<boolean>(true)
    const modalBodyRef = React.useRef(null);
    const onClose = (): void => {
        setShowMoadal(false);
    }

    const onDownload = (): void => {
        const pdf = new jsPDF('p', 'px', 'a4');
        pdf.setFont('times', 'normal');
        if (modalBodyRef.current) {
            pdf.html(modalBodyRef.current, {
                callback: async function (pdf) {
                    await addHeaderAndFooter(pdf);
                    pdf.save("download.pdf");
                },
                x: 25,
                y: 70,
                html2canvas: { scale: 0.5 },  // Adjust scale as needed
            });
        }
    }

    const onPreview = (preview: string): void => {
        window.open(preview, '_blank');
    }

    const addHeaderAndFooter = async (pdf: jsPDF): Promise<void> => {
        const pageCount = (pdf.internal as any).getNumberOfPages();

        const imgData: string = await fetch(logo)
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result as string);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            }));

        for (let i = 1; i <= pageCount; i++) {
            pdf.setPage(i);

            pdf.addImage(imgData, 'png', 25, 15, 75, 40);

            pdf.setFontSize(16);  // Set font size for header
            pdf.text('Certificado de envío', pdf.internal.pageSize.getWidth() / 2, 40, { align: 'center' });

            // Add footer
            pdf.setFontSize(10);  // Set font size for footer
            pdf.text('Powered by Mayasoft', pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 10, { align: 'center' });
        }
    }

    const detailRow = (label: string, value: string) => {
        return (
            <Container>
                <Row style={{ paddingTop: '15px' }}>
                    <Col xs={6} className="border bg-light rounded-start" >
                        {label}
                    </Col>
                    <Col xs={6}
                        className="border bg-muted rounded-end"
                        style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}
                    >
                        {value ? value : '- - -'}
                    </Col>
                </Row>
            </Container>
        );
    }

    useEffect(() => {
        if (emailDataDetail) {
            setIsEmail(!!emailDataDetail.email)
        }
    }, [emailDataDetail]);

    return (
        <Modal
            show={showModal}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Certificado de envío de {`${isEmail ? 'correo electrónico' : 'SMS'}`}
                </Modal.Title>
            </Modal.Header>
            {!!emailDataDetail && isEmail ?
                <Modal.Body ref={modalBodyRef}>
                    {detailRow('Campaña', emailDataDetail.campaign)}
                    {detailRow('Documento Id', emailDataDetail.documentId)}
                    {detailRow('Correo electrónico', emailDataDetail.email)}
                    {detailRow('Asunto', emailDataDetail.subject)}
                    {detailRow('Fecha de creación', customFormatDate(emailDataDetail.creationDate))}
                    {detailRow('Fecha de envío', customFormatDate(emailDataDetail.sendingDate))}
                    {detailRow('Estado', emailDataDetail.status)}
                    {detailRow('Correo válido', emailDataDetail.validEmail)}
                </Modal.Body> :
                !!emailDataDetail && !isEmail ?
                    <Modal.Body ref={modalBodyRef}>
                        {detailRow('Campaña', emailDataDetail.campaign)}
                        {detailRow('Documento Id', emailDataDetail.documentId)}
                        {detailRow('Teléfono', emailDataDetail.phone)}
                        {detailRow('Fecha de creación', customFormatDate(emailDataDetail.creationDate))}
                        {detailRow('Fecha de envío', customFormatDate(emailDataDetail.sendingDate))}
                        {detailRow('Estado', emailDataDetail.status)}
                    </Modal.Body>
                    :
                    <Modal.Body>Cargando...</Modal.Body>
            }
            <Modal.Footer>
                {!!emailDataDetail && emailDataDetail.preview && isEmail &&
                    <Button onClick={() => onPreview(emailDataDetail.preview)}>Ver</Button>
                }
                <Button onClick={onDownload}>Descargar</Button>
                <Button onClick={onClose}>Cerrar</Button>
            </Modal.Footer>

        </Modal>
    );

};
export default CustomModal;