import axios from "axios";
import { Button } from "react-bootstrap";
import { getAxiosHeader } from "../../api";
import * as config from '../../common/config/config';
import { customFormatDate } from "../../common/utils/date";
import { createFilterPayload, createFilterStructure, createPayload } from "../../common/utils/pagination";
import { EmailIcetexData, EmailicetexDataDetail } from "../../interfaces/emailData.interface";
import { PaginationType } from "../../interfaces/response.interface";
import { ColumnType, FilterModel, PaginationModel, TableModel } from "../../interfaces/table.interface";


export interface EmailIcetexFilterModel {
    documentId?: string;
    email?: string;
    phone?: string;
    tickler?: string;
    dateRange?: {
        startDate?: string;
        endDate?: string;
    }
}

export const defaultPagination: PaginationModel = {
    page: 1,
    limit: 10
}

export const getDefaultEmailIcetextFilterModel = (): EmailIcetexFilterModel => {
    /**
     * By default this day
     */
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    return {
        documentId: '',
        email: '',
        phone: '',
        tickler: '',
        dateRange: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
        }

    }
}

export const getColumns: (onDetail: (id: number) => void)
    => ColumnType<EmailIcetexData>[] =
    (onDetail) => {
        const columns = [
            {
                field: 'documentId',
                headerName: 'Documento Id',
            },
            {
                field: 'email',
                headerName: 'Email',
                render: ({ email }) =>
                    email ? email : '- - -',

            },
            {
                field: 'phone',
                headerName: 'Teléfono',
                render: ({ phone }) =>
                    phone ? phone : '- - -',
            },
            {
                field: 'tickler',
                headerName: 'Ticket Jira',
            },
            {
                field: 'campaign',
                headerName: 'Campaña',
            },
            {
                field: 'sendingDate',
                headerName: 'Fecha de envío',
                render: ({ sendingDate }) =>
                    customFormatDate(sendingDate),
            },
            {
                field: 'id',
                headerName: 'Detalle',
                render: ({ id }) => {
                    return (
                        <Button
                            className="bi bi-eye"
                            onClick={() => onDetail(id)}
                            variant="link"
                        ></Button>
                    )
                }
            },
        ]
        return columns;
    };

export const createFilterModel = (
    { documentId, email, dateRange, phone, tickler }:
        EmailIcetexFilterModel) => {

    const filterIdentificationCard = createFilterStructure(
        'document_id',
        'contains',
        documentId,
    );
    const filterEmail = createFilterStructure(
        'email',
        'contains',
        email,
    );
    const filterPhone = createFilterStructure(
        'phone',
        'contains',
        phone,
    );
    const filterTickler = createFilterStructure(
        'tickler',
        'contains',
        tickler,
    );

    let modelFilter = [
        filterIdentificationCard,
        filterEmail,
        filterPhone,
        filterTickler
    ].filter((item) => item);

    if (dateRange?.startDate && dateRange?.endDate) {
        console.log('dateRange', dateRange)
        const filterDateRange = createFilterStructure(
            'sending_date',
            'between_date',
            dateRange,
        );

        modelFilter = [
            ...modelFilter,
            filterDateRange
        ]
    }

    return modelFilter;
}

export const onFetchEmailsIcetexData = async (
    pagination: TableModel<EmailIcetexData>
) => {
    const url = `${config.BACKEND_URL}/api/v1/email-data${createPayload(pagination)}`;
    const response = await axios.get<PaginationType<EmailIcetexData>>(url, getAxiosHeader());
    return response;
}

export const onFetchEmailsIcetexDetailData = async (
    id: number
) => {
    const url = `${config.BACKEND_URL}/api/v1/email-data/${id}`;
    const response = await axios.get<EmailicetexDataDetail>(url, getAxiosHeader());
    return response.data[0];
}

export const onDownloadExcelData = async (
    filterModel: FilterModel<EmailIcetexData>[]
) => {
    const url = `${config.BACKEND_URL}/api/v1/email-data/download/reporte${createFilterPayload(filterModel)}`;
    const response = await axios.get(url, { ...getAxiosHeader(), responseType: 'blob' });
    return response.data;
}


